<template>
  <div class="inner-layout">

    <div class="page-title">이용약관</div>

    <div class="pageAgreementFullText" v-html="content"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: null
    }
  },
  created() {
    this.setItem();
  },
  methods:{
    async setItem() {
      const { result, data } = await this.$api.getPolicy({ target: 1 })
      if (result === 'success') this.content = data
    },
  },
}
</script>
